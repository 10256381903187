import React from 'react';

const NotFound = () => {
  return (
    <div>
      <h2 className="font-bold text-3xl"></h2>
      <h3>Page Not Found</h3>
    </div>
  );
};

export default NotFound;
